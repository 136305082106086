<script>
import { getFinanceTrackerPayableSuppliers } from '@/graphql/admin/accounting/financeTrackerQueries.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  query({ filter }) {
    return {
      query: getFinanceTrackerPayableSuppliers,
      variables: { filter }
    };
  }
};
</script>
